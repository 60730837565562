var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"bordered":false}},[_c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"金额","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['total_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['total_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"50%"},attrs:{"precision":2}})],1),_c('a-form-item',{attrs:{"label":"税额","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['total_tax', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['total_tax', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],staticStyle:{"width":"50%"},attrs:{"precision":2}})],1),_c('a-form-item',{attrs:{"label":"发票方式","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'method',
            {
              rules: [
                { required: true, message: '请选择' }
              ],
            },
          ]),expression:"[\n            'method',\n            {\n              rules: [\n                { required: true, message: '请选择' }\n              ],\n            },\n          ]"}],attrs:{"placeholder":"选择","show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption}},_vm._l((this.method),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(_vm._s(val))])}),1)],1),_c('a-form-item',{attrs:{"label":"已存档","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_archived', {rules: [{required: false, message: '必填项，请填写信息'}]}]),expression:"['is_archived', {rules: [{required: false, message: '必填项，请填写信息'}]}]"}],attrs:{"checked-children":"是","un-checked-children":"否"}})],1),_c('a-form-item',{attrs:{"label":"备注","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]),expression:"['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]"}],attrs:{"rows":4}})],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.buttonCol,false),[_c('a-row',[_c('a-col',{attrs:{"span":"6"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1),_c('a-col',{attrs:{"span":"10"}},[_c('a-button',{on:{"click":_vm.handleGoBack}},[_vm._v("返回")])],1),_c('a-col',{attrs:{"span":"8"}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }